import { mutations } from './mutations'
import { actions } from './actions'

export * from './mutations'
export * from './actions'

export const moduleValidators = {
  state: {
    list: {
      kichain: [],
      juno: [],
      lum: []
    },
    selectedValidator: {
      kichain: [],
      juno: [],
      lum: []
    }
  },
  mutations,
  actions
}
