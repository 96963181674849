import axios from 'axios'
import { SET_POSTS } from './mutations'

export const FETCH_POSTS = 'FETCH_POSTS'

const blogData : any = {
  apis: {
    medium: 'https://api.rss2json.com/v1/api.json?rss_url=https://invictastake.medium.com/feed',
    twitter: 'https://gist.githubusercontent.com/TarInvicta/69911eec6cd48611d4a20c0cdd79baa3/raw/blog-temp.json'
  }
}

export const actions = {
  [FETCH_POSTS]: async ({ commit, state }: { commit: any, state: any }) => {
    const blogs = []
    for (const url in blogData.apis) {
      const response = await axios.get(blogData.apis[url])
      blogs.push(...response.data.items)
    }

    // Sort by date of publication
    blogs.sort((a, b) => (Date.parse(a.pubDate) > Date.parse(b.pubDate)) ? -1 : ((Date.parse(b.pubDate) > Date.parse(a.pubDate)) ? 1 : 0))

    commit(SET_POSTS, blogs)
  }
}
