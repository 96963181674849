<template>
  <div id="burger" :class="{ 'active' : isBurgerActive }" @click.prevent="toggle">
    <slot>
      <button type="button" class="burger-button" title="Menu">
        <span class="hidden">Toggle menu</span>
        <span class="burger-bar burger-bar--1"></span>
        <span class="burger-bar burger-bar--2"></span>
        <span class="burger-bar burger-bar--3"></span>
      </button>
    </slot>
  </div>
</template>
<script>
// import { store, mutations } from '@/store.js'
import { TOGGLE_NAV } from '@/store/app/mutations'

export default {
  // data: () => ({
  //     isBurgerActive: false
  // }),
  computed: {
    isBurgerActive () {
      return this.$store.state.app.isNavOpen
    }
  },
  methods: {
    toggle () {
      this.$store.commit(TOGGLE_NAV)
      // mutations.toggleNav()
    }
  }
}
</script>
<style></style>
