<template>
<div class="sidebar">
  <div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen"></div>
  <transition name="slide">
    <div v-if="isPanelOpen" class="sidebar-panel">
      <slot></slot>
      <Footer/>
    </div>
  </transition>
</div>

</template>
<script>
// import { store, mutations } from '@/store.js'
import { TOGGLE_NAV } from '@/store/app/mutations'
import Footer from '@/components/footer'

export default {
  components: {
    Footer
  },
  methods: {
    closeSidebarPanel () {
      this.$store.commit(TOGGLE_NAV)
    }
  },
  computed: {
    isPanelOpen () {
      return this.$store.state.app.isNavOpen
    }
  }
}
</script>
<style></style>
