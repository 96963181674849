import { mutations } from './mutations'
import { actions } from './actions'

export * from './mutations'
export * from './actions'

export const moduleInflation = {
  state: {
    inflationRate: {
      kichain: 0,
      juno: 0,
      lum: 0
    },
    bondedRate: {
      kichain: 0,
      juno: 0,
      lum: 0
    },
    prices: {
      kichain: [],
      juno: [],
      lum: []
    }
  },
  mutations,
  actions
}
