<template>
<b-modal id="disclaimer-modal" hide-footer hide-header no-close-on-esc >
  <b-row class="pb-5">
    <b-col>
      <span >Disclaimer</span>
    </b-col>
  </b-row>
  <b-row>
    <b-col>
        <span  class="card-label">The content of this site is not and should not be considered as a financial advice. Please do your own research.</span>
    </b-col>
  </b-row>
  <b-row class="pt-5">
    <b-col>
      <span  class="card-label">The team</span>
    </b-col>
  </b-row>

  <b-row class="pt-5">
    <b-col>
      <span class="card-label">invictastake ©2024</span>
    </b-col>
  </b-row>
</b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol
} from 'bootstrap-vue'
export default {
  components: {
    BModal,
    BRow,
    BCol
  },
  props: {
  }
}
</script>

<style scoped>
.important{
  color: var(--blueColor)
}
</style>
