import { mutations } from './mutations'
import { actions } from './actions'

export * from './mutations'
export * from './actions'

export const moduleBlog = {
  state: {
    posts: {}
  },
  mutations,
  actions
}
