export const SET_VALIDATORS_LIST = 'SET_VALIDATORS_LIST'
export const SET_CURRENT_VALIDATOR = 'SET_CURRENT_VALIDATOR'

export const mutations = {
  [SET_VALIDATORS_LIST] (state: any, validatorsList: any): void {
    state.validators.list[validatorsList[0]] = validatorsList[1]
  },
  [SET_CURRENT_VALIDATOR] (state: any, validator: any): void {
    state.validators.selectedValidator[validator[0]] = validator[1]
  }
}
