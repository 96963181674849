import { mutations } from './mutations'

export * from './mutations'

export const moduleApp = {
  state: {
    isNavOpen: false
  },
  mutations
}
