import Vue from 'vue'
import Vuex from 'vuex'
import { moduleInflation } from './inflation'
import { moduleValidators } from './validators'
import { moduleBlog } from './blog'
import { moduleApp } from './app'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    inflationRate: moduleInflation.state.inflationRate,
    bondedRate: moduleInflation.state.bondedRate,
    prices: moduleInflation.state.prices,
    validators: moduleValidators.state,
    blog: moduleBlog.state,
    app: moduleApp.state
  },
  mutations: {
    ...moduleInflation.mutations,
    ...moduleValidators.mutations,
    ...moduleBlog.mutations,
    ...moduleApp.mutations
  },
  actions: {
    ...moduleInflation.actions,
    ...moduleValidators.actions,
    ...moduleBlog.actions
  }
})
