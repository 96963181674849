<template>
<div class="footer">
  <b-container fluid>
    <b-row align-v="center" align-h="between">
      <b-col>
        <a v-b-modal="'disclaimer-modal'"> disclaimer </a>
      </b-col>
      <b-col class="hidden-mobile">
        <span > invictastake ©2024 </span>
      </b-col>
      <b-col>
        <span class="hidden-mobile"> Contact us </span>
        <a href="https://t.me/InvictaStake" target="_blank"><unicon class="mx-1 my-0 footer-contact" name="telegram" width="26" height="26"/></a>
        <a href="mailto:contact@invictastake.com" target="_blank"><unicon class="mx-1 my-0 footer-contact" name="at"  width="26" height="26"/></a>
        <a href="https://twitter.com/InvictaStake" target="_blank"><unicon class="mx-1 my-0 footer-contact" name="twitter"  width="26" height="26"/></a>
      </b-col>
    </b-row>
  </b-container>
  <!-- <DisclaimerModal/> -->
</div>
</template>

<script>
import Vue from 'vue'
// import DisclaimerModal from '@/components/disclaimer'
import {
  BRow,
  BCol,
  BContainer
} from 'bootstrap-vue'
export default Vue.extend({
  name: 'Footer',
  components: {
    BRow,
    BCol,
    BContainer
    // DisclaimerModal
  },
  props: {
    msg: String
  },
  data () {
    return {
      dark: false
    }
  },
  methods: {
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@media (max-width: 767px) {
        .hidden-mobile {
          display: none;
        }
        .hidden-mobile {
          display: none;
        }
      }
</style>
