<template>
<div id="app">
  <TopBar/>
  <Footer class="hidden-mobile"/>
  <DisclaimerModal/>

</div>
</template>

<script>
import Vue from 'vue'
import TopBar from '@/components/topbar'
import Footer from '@/components/footer'
import DisclaimerModal from '@/components/disclaimer'

import { FETCH_INFLATION_RATE, FETCH_BONDED_RATE, FETCH_PRICES } from '@/store/inflation/actions'
import { FETCH_VALIDATORS_LIST } from '@/store/validators/actions'
import { FETCH_POSTS } from '@/store/blog/actions'
import { SET_DARK_MODE } from '@/store/app/mutations'

export default Vue.extend({
  name: 'Home',
  components: {
    TopBar,
    Footer,
    DisclaimerModal
  },
  created () {
    if (localStorage.getItem('darkmode')) {
      this.$store.commit(SET_DARK_MODE, localStorage.getItem('darkmode') === 'true')
      if (localStorage.getItem('darkmode') === 'true') {
        var element = document.body
        element.classList.add('dark-mode')
      }
    } else {
      this.$store.commit(SET_DARK_MODE, 'true')
      element = document.body
      element.classList.add('dark-mode')
    }
    this.$store.dispatch(FETCH_INFLATION_RATE)
    this.$store.dispatch(FETCH_BONDED_RATE)
    this.$store.dispatch(FETCH_PRICES)
    this.$store.dispatch(FETCH_VALIDATORS_LIST)
    this.$store.dispatch(FETCH_POSTS)
  }
})
</script>
<style lang="scss">
@media (max-width: 767px) {
        .hidden-mobile {
          display: none;
        }
      }
</style>
