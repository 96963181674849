<template>
  <div class="home">
    <div style="margin-top:10px">
    <b-row  class="spacer">
      <b-col cols="2" />
      <b-col cols="8">
        <div>
          <h1 class="title">
            Professional dPoS Validator.
          </h1>
        </div>
        <div style="margin-top:80px; margin-bottom:80px" class="warning">
          Invicta’s operations concluded at the end of August 2023. We would like to thank all our delegators for their support. We will continue to support the Cosmos ecosystem as delegators to other validators.
        </div>
        <div>
          <h5>Our Validators : </h5>
          <div class="row justify-content-center" style="margin-top:20px">
            <div class="col-4 col-sm-2 rounded-div">
              <h5><a target="_blank" href="https://www.mintscan.io/ki-chain/validators/kivaloper1s93q7c4vyu62w9lajny3rmjhfj6248dydzxcky">KiChain</a></h5>
              <a target="_blank" href="https://www.mintscan.io/ki-chain/validators/kivaloper1s93q7c4vyu62w9lajny3rmjhfj6248dydzxcky">
                <svg xmlns="http://www.w3.org/2000/svg" width="90%" height="90%"   viewBox="0 0 208 208" class="logo" fill="#989ba7">
                <path id="logo" class="cls-1" d="M150.291,136.911H117.269l-32.854-44v44H57.709V42H84.415V84.247L116.186,42h28.691L112.4,86.413ZM59.5,152.5a8.918,8.918,0,0,1,3.2-3.74,12.98,12.98,0,0,1,14.471-1.393,13.52,13.52,0,0,1,3.07,2.281q0.454,0.449.865,0.94a13.611,13.611,0,0,1,1.452,2.106,13.223,13.223,0,0,1,1.689,6.257,13.277,13.277,0,1,1-26.526-.541,13.022,13.022,0,0,1,1.209-4.814Z"/>
              </svg>
              </a>
            </div>
            <div class="col-4 col-sm-2 rounded-div">
              <h5><a target="_blank" href="https://www.mintscan.io/juno/validators/junovaloper19w9huamhq32shes95dr4kmqequ5pe450up6wvf">Juno</a></h5>
              <a target="_blank" href="https://www.mintscan.io/juno/validators/junovaloper19w9huamhq32shes95dr4kmqequ5pe450up6wvf">
                <svg xmlns="http://www.w3.org/2000/svg" width="90%" height="90%"   viewBox="0 0 50 50"  class="logo"  fill="#989ba7" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
                  <g transform="matrix(0.721351,0,0,0.811444,6.54562,5.10326)">
                      <path d="M27.411,7.92C27.411,7.08 26.645,6.399 25.7,6.399C25.7,6.399 25.699,6.399 25.698,6.399C24.754,6.399 23.988,7.08 23.988,7.92C23.988,14.049 23.988,36.907 23.988,43.037C23.988,43.877 24.754,44.558 25.698,44.558C25.699,44.558 25.7,44.558 25.7,44.558C26.645,44.558 27.411,43.877 27.411,43.037C27.411,36.907 27.411,14.049 27.411,7.92Z"/>
                  </g>
                  <g transform="matrix(0.000599295,-0.721351,0.258566,0.000214816,18.4279,54.7844)">
                      <path d="M27.411,11.171C27.411,8.536 26.645,6.399 25.7,6.399C25.7,6.399 25.699,6.399 25.698,6.399C24.754,6.399 23.988,8.536 23.988,11.171C23.988,18.502 23.988,32.454 23.988,39.785C23.988,42.421 24.754,44.558 25.698,44.558C25.699,44.558 25.7,44.558 25.7,44.558C26.645,44.558 27.411,42.421 27.411,39.785C27.411,32.454 27.411,18.502 27.411,11.171Z"/>
                  </g>
                  <g transform="matrix(0.000599295,-0.721351,0.579332,0.000481307,10.2753,38.6396)">
                      <path d="M27.411,8.529C27.411,7.352 26.645,6.399 25.7,6.399C25.7,6.399 25.699,6.399 25.698,6.399C24.754,6.399 23.988,7.352 23.988,8.529C23.988,15.216 23.988,35.741 23.988,42.428C23.988,43.604 24.754,44.558 25.698,44.558C25.699,44.558 25.7,44.558 25.7,44.558C26.645,44.558 27.411,43.604 27.411,42.428C27.411,35.741 27.411,15.216 27.411,8.529Z"/>
                  </g>
                  <g transform="matrix(0.509521,-0.510622,0.393855,0.393005,1.94156,23.2426)">
                      <path d="M27.411,8.617C27.411,7.392 26.645,6.399 25.7,6.399C25.7,6.399 25.699,6.399 25.698,6.399C24.754,6.399 23.988,7.392 23.988,8.617C23.988,15.364 23.988,35.593 23.988,42.34C23.988,43.565 24.754,44.558 25.698,44.558C25.699,44.558 25.7,44.558 25.7,44.558C26.645,44.558 27.411,43.565 27.411,42.34C27.411,35.593 27.411,15.364 27.411,8.617Z"/>
                  </g>
                  <g transform="matrix(-0.51096,-0.509182,0.392744,-0.394115,28.1955,43.2602)">
                      <path d="M27.411,8.617C27.411,7.392 26.645,6.399 25.7,6.399C25.7,6.399 25.699,6.399 25.698,6.399C24.754,6.399 23.988,7.392 23.988,8.617C23.988,15.364 23.988,35.593 23.988,42.34C23.988,43.565 24.754,44.558 25.698,44.558C25.699,44.558 25.7,44.558 25.7,44.558C26.645,44.558 27.411,43.565 27.411,42.34C27.411,35.593 27.411,15.364 27.411,8.617Z"/>
                  </g>
                </svg>
              </a>
            </div>
            <div class="col-4 col-sm-2 rounded-div">
              <h5><a target="_blank" href="https://www.mintscan.io/lum/validators/lumvaloper1k5zmdqu63fy0ka80ed3nnqehkc9xr8930argf0">Lum</a></h5>
              <a target="_blank" href="https://www.mintscan.io/lum/validators/lumvaloper1k5zmdqu63fy0ka80ed3nnqehkc9xr8930argf0">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="90%" height="90%" viewBox="0 0 200 200" class="logo"  fill="#989ba7" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"  stroke="none">
            <path d="M947 1818 c-77 -21 -138 -115 -122 -191 22 -100 115 -160 210 -136 76 20 125 83 125 162 0 56 -18 95 -60 130 -26 22 -92 49 -110 46 -3 -1 -22 -6 -43 -11z"/>
            <path d="M488 1594 c-15 -8 -35 -28 -44 -45 -52 -102 88 -204 169 -123 52 52 41 136 -23 169 -36 19 -67 19 -102 -1z"/>
            <path d="M1385 1599 c-36 -21 -55 -53 -55 -95 0 -50 27 -88 74 -104 99 -32 178 93 109 170 -31 35 -94 49 -128 29z"/>
            <path d="M290 1150 c-133 -23 -183 -194 -84 -287 111 -105 284 -30 284 123 0 105 -94 182 -200 164z"/>
            <path d="M952 1147 c-80 -16 -143 -111 -127 -190 42 -204 335 -178 335 30 0 108 -97 183 -208 160z"/>
            <path d="M1641 1150 c-68 -16 -120 -74 -128 -143 -7 -60 11 -110 52 -146 70 -61 160 -61 230 0 128 113 14 326 -154 289z"/>
            <path d="M483 630 c-75 -45 -61 -160 22 -189 86 -29 162 44 135 131 -12 43 -58 78 -100 78 -14 0 -39 -9 -57 -20z"/>
            <path d="M1394 641 c-64 -27 -84 -116 -39 -169 30 -36 66 -47 111 -32 75 25 99 112 47 170 -30 33 -82 46 -119 31z"/>
            <path d="M911 493 c-97 -48 -116 -193 -35 -270 111 -104 284 -29 284 123 0 124 -134 203 -249 147z"/>
            </g>
            </svg>
          </a>
        </div>

            <!-- Force next columns to break to new line -->
            <div class="w-100"></div>
            <!-- <div class="col-4 col-sm-2 rounded-div">
              <h5><a target="_blank" href="https://blockchain.ki/">KiChain</a></h5>
              <svg xmlns="http://www.w3.org/2000/svg" width="90%" height="90%" viewBox="0 0 208 208" class="logo" fill="#0a112b">
                <path id="logo" class="cls-1" d="M150.291,136.911H117.269l-32.854-44v44H57.709V42H84.415V84.247L116.186,42h28.691L112.4,86.413ZM59.5,152.5a8.918,8.918,0,0,1,3.2-3.74,12.98,12.98,0,0,1,14.471-1.393,13.52,13.52,0,0,1,3.07,2.281q0.454,0.449.865,0.94a13.611,13.611,0,0,1,1.452,2.106,13.223,13.223,0,0,1,1.689,6.257,13.277,13.277,0,1,1-26.526-.541,13.022,13.022,0,0,1,1.209-4.814Z"/>
              </svg>
            </div>
            <div class="col-4 col-sm-2 rounded-div">
              <h5><a target="_blank" href="https://junoscan.com/">Juno</a></h5>
                <svg xmlns="http://www.w3.org/2000/svg" width="90%" height="90%" viewBox="0 0 208 208" class="logo" fill="#0a112b">
                  <path id="logo" class="cls-1" d="M150.291,136.911H117.269l-32.854-44v44H57.709V42H84.415V84.247L116.186,42h28.691L112.4,86.413ZM59.5,152.5a8.918,8.918,0,0,1,3.2-3.74,12.98,12.98,0,0,1,14.471-1.393,13.52,13.52,0,0,1,3.07,2.281q0.454,0.449.865,0.94a13.611,13.611,0,0,1,1.452,2.106,13.223,13.223,0,0,1,1.689,6.257,13.277,13.277,0,1,1-26.526-.541,13.022,13.022,0,0,1,1.209-4.814Z"/>
                </svg>
              </div> -->
            </div>
          </div>
        <!-- <div style="margin-top:25px">
          <h6>Delegation address:</h6>
          <div style="margin:15px; word-wrap: break-word;">
            <h5><a target="_blank" href="https://blockchain.ki/validator/kivaloper1s93q7c4vyu62w9lajny3rmjhfj6248dydzxcky" >kivaloper1s93q7c4vyu62w9lajny3rmjhfj6248dydzxcky</a></h5>
          </div>
        </div> -->
      </b-col>
      <b-col cols="2" />
    </b-row>
  </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  // BImg,
  BRow,
  BCol
} from 'bootstrap-vue'
// import Landing from '@/components/landing.vue'

export default Vue.extend({
  name: 'Home',
  components: {
    // BImg,
    BRow,
    BCol
  },
  data () {
    return {
      main: require('@/assets/img/main.png')
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.dark-mode .logo{
  filter: brightness(0) invert(1);
}

.spacer{
  margin-top:120px;
}
@media (min-width: 2000px) {
        .spacer{
          margin-top:0px;
        }
      }
</style>
