import axios from 'axios'
import { SET_VALIDATORS_LIST, SET_CURRENT_VALIDATOR } from './mutations'

export const FETCH_VALIDATORS_LIST = 'FETCH_VALIDATORS_LIST'

// TODO: Move to env
const chainData : any = {
  apis: {
    kichain: 'https://api-mainnet.blockchain.ki/cosmos/staking/v1beta1/validators?pagination.limit=200',
    juno: 'https://juno-api.polkachu.com/cosmos/staking/v1beta1/validators?pagination.limit=200',
    lum: 'https://node0.mainnet.lum.network/rest/cosmos/staking/v1beta1/validators?pagination.limit=200'
  },
  invicta_address: {
    kichain: 'DXeOGOcqwLWXBe+veCgcj3gnlcVArs9ha1sZ+nYjL/I=',
    juno: 'TB45UV+EBZq1Iw6weEEkuHLkMcrLQsWsxYv6AfPkq9w=',
    lum: '+nUbJYtEpRWM5Ez6Xu+thQsQjSR0oh/rUfMqPWePHkY='
  }
}

export const actions = {
  [FETCH_VALIDATORS_LIST]: async ({ commit, state }: {commit: any, state: any}) : Promise<void> => {
    for (const chain in state.validators.list) {
      // if (state.validators.list.kichain.length === 0) {
      const response = await axios.get(chainData.apis[chain])
      const current = response.data.validators.filter((obj : any) => {
        return obj.consensus_pubkey.key === chainData.invicta_address[chain]
      })
      commit(SET_VALIDATORS_LIST, [chain, response.data.validators])
      commit(SET_CURRENT_VALIDATOR, [chain, current[0]])
      // }
    }
    // TODO handle error
  }
}
