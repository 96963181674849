export const SET_IS_NAV_OPEN = 'SET_IS_NAV_OPEN'
export const TOGGLE_NAV = 'TOGGLE_NAV'
export const SET_DARK_MODE = 'SET_DARK_MODE'

export const mutations = {
  [SET_IS_NAV_OPEN] (state: any, yesno: any): void {
    state.app.isNavOpen = yesno
  },
  [TOGGLE_NAV] (state: any): void {
    state.app.isNavOpen = !state.app.isNavOpen
  },
  [SET_DARK_MODE] (state: any, yesno: any): void {
    state.app.darkMode = yesno
  }
}
