import axios from 'axios'
import { SET_INFLATION_RATE, SET_BONDED_RATE, SET_PRICES } from './mutations'

export const FETCH_INFLATION_RATE = 'FETCH_INFLATION_RATE'
export const FETCH_BONDED_RATE = 'FETCH_BONDED_RATE'
export const FETCH_PRICES = 'FETCH_PRICES'

// TODO: Move to env
const priceApi = 'https://api.coingecko.com/api/v3/coins/'
const chainData : any = {
  kichain: {
    api: 'https://api-mainnet.blockchain.ki/',
    denom: 'uxki',
    coingecko: 'ki'
  }
  ,
  juno: {
    api: 'https://juno-api.polkachu.com/',
    denom: 'ujuno',
    coingecko: 'juno-network'
  },
  lum: {
    api: 'https://node0.mainnet.lum.network/rest/',
    denom: 'ulum',
    coingecko: 'lum-network'
  }
}

export const actions = {
  [FETCH_INFLATION_RATE]: async ({ commit, state }:{commit: any, state: any}) => {
    // if (state.inflationRate === 0) {
    for (const chain in chainData) {
      const response = await axios.get(chainData[chain].api + '/cosmos/mint/v1beta1/inflation')
      commit(SET_INFLATION_RATE, [chain, Math.round(response.data.inflation * 1000) / 1000])
      // TODO handle error
    }
  },
  [FETCH_BONDED_RATE]: async ({ commit, state }:{commit: any, state: any}) => {
    // if (state.inflationRate === 0) {
    for (const chain in chainData) {
      const responseBonded = await axios.get(chainData[chain].api + 'cosmos/staking/v1beta1/pool')
      const responseSupply = await axios.get(chainData[chain].api + 'cosmos/bank/v1beta1/supply?pagination.limit=500')

      const supply = responseSupply.data.supply.filter((obj : any) => {
        return obj.denom === chainData[chain].denom
      })

      const bonded = responseBonded.data.pool.bonded_tokens / supply[0].amount

      commit(SET_BONDED_RATE, [chain, Math.round(bonded * 1000) / 1000])
      // TODO handle error
    }
  },
  [FETCH_PRICES]: async ({ commit, state }:{commit: any, state: any}) => {
    // if (state.inflationRate === 0) {
    for (const chain in chainData) {
      const now = Math.floor((new Date()).getTime() / 1000);
      const yesterday = Math.floor((new Date(new Date().getTime() - 7200000)).getTime() / 1000);
      const responsePrice = await axios.get(priceApi + `${chainData[chain].coingecko}/market_chart/range?vs_currency=usd&from=${yesterday}&to=${now}`)
      const prices = []
      const labels = []
      for (var price of responsePrice.data.prices){
        const date = new Date(price[0])

        labels.push(date.getDate()+
          "/"+(date.getMonth()+1)+
          "/"+date.getFullYear()+
          date.getHours()+":"+date.getMinutes()+":"+date.getSeconds());
        prices.push(Math.round(price[1] * 1000) / 1000);
      }

      // commit(SET_PRICES, [chain, [Math.round(responsePrice.data.prices[0][1]* 1000) / 1000, Math.round(responsePrice.data.prices.pop()[1]* 1000) / 1000]])
      commit(SET_PRICES, [chain, [prices, labels]])
    }
  }
}
