import Vue from 'vue'
import App from './App.vue'
import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd'
import router from './router'
import store from './store'
import { BootstrapVue, ModalPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/style/index.scss'

import {
  uniMoonset,
  uniSunset,
  uniTelegram,
  uniTwitter,
  uniAt,
  uniSearch
} from 'vue-unicons/dist/icons'

Unicon.add([
  uniMoonset,
  uniSunset,
  uniTelegram,
  uniTwitter,
  uniAt,
  uniSearch
])
Vue.use(Unicon)
Vue.use(ModalPlugin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
