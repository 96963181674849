export const SET_INFLATION_RATE = 'SET_INFLATION_RATE'
export const SET_BONDED_RATE = 'SET_BONDED_RATE'
export const SET_PRICES = 'SET_PRICES'

export const mutations = {
  [SET_INFLATION_RATE] (state: { inflationRate: any }, inflationRate: any): void {
    state.inflationRate[inflationRate[0]] = inflationRate[1]
  },
  [SET_BONDED_RATE] (state: { bondedRate: any }, bondedRate: any): void {
    state.bondedRate[bondedRate[0]] = bondedRate[1]
  },
  [SET_PRICES] (state: { prices: any }, prices: any): void {
    state.prices[prices[0]] = prices[1]
  }
}
